@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';
@use '../../styleguide/mixins.scss';

.modal {
  width: 530px;
  max-width: 95vw;
  max-height: 80vh;

  @include mixins.rounded();
  padding: 0;
  header {
    display: none !important;
  }
}

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 24px;

  svg {
    fill: none;
  }

  h3 {
    font-size: 28px;
    font-stretch: condensed;
  }
}

.subTitle {
  color: colors.$secondary-text;
}

.actionWrapper {
  margin-top: vars.$gutter;
  display: flex;
  gap: 8px;
  width: 100%;
}

.checkboxLabel {
  align-items: center;
  margin-top: vars.$nodeSpacing;
}
